import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore"; // Import Firestore functions
import { db } from "../config/firebase"; // Ensure the correct path to your Firebase setup file
import { useNavigate } from "react-router-dom"; // For navigation to details page

function JobOpportunities() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "jobs")); // Fetch jobs from Firestore
        const jobsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobs(jobsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching job opportunities:", error);
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  return (
    <div className="container mx-auto p-12 flex flex-col justify-center items-center min-h-screen bg-gray-900 text-gray-100">
      <p className="text-lg text-gray-400 italic mb-4">
        For Details, View Current Positions available:
      </p>

      {loading ? (
        <p className="text-2xl text-gray-200 font-semibold">Loading...</p>
      ) : jobs.length > 0 ? (
        <ul className="w-full max-w-4xl space-y-6">
          {jobs.map((job) => (
            <li
              key={job.id}
              className="border border-gray-700 rounded-lg p-6 shadow hover:shadow-lg transition bg-gray-800"
            >
              <h2 className="text-2xl font-semibold text-gray-100">
                {job.jobDetails?.title}
              </h2>
              <p className="text-gray-400">
                <strong>Location:</strong> {job.jobDetails?.location}
              </p>
              <p className="text-gray-400">
                <strong>Type:</strong> {job.jobDetails?.jobType} | {job.jobDetails?.workNature}
              </p>
              <p className="text-gray-400">
                <strong>Client:</strong> {job.jobDetails?.clientName}
              </p>
              <p className="text-gray-400">
                <strong>Pay:</strong> ${job.jobDetails?.payPerHour} USD/hour
              </p>
              <p className="text-gray-400">
                <strong>HR Email:</strong>{" "}
                <a
                  href={`mailto:${job.jobDetails?.email}`}
                  className="text-blue-500 hover:underline"
                >
                  {job.jobDetails?.email}
                </a>{" "}
                (Share your profile here)
              </p>
              <button
                className="mt-4 px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-500 transition"
                onClick={() => navigate(`/job-details/${job.id}`)} // Navigate to job details page
              >
                View Details
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-2xl text-gray-200 font-semibold">
          No Job Opportunities Found!
        </p>
      )}
    </div>
  );
}

export default JobOpportunities;
