import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";

function JobDetails() {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null); // State to hold the nested jobDetails
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const jobDoc = await getDoc(doc(db, "jobs", id));
        if (jobDoc.exists()) {
          const data = jobDoc.data();
          setJobDetails(data.jobDetails); // Extract the nested jobDetails field
        } else {
          console.error("No such job!");
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [id]);

  if (loading) {
    return <div className="text-gray-100 text-center p-12">Loading...</div>;
  }

  if (!jobDetails) {
    return (
      <div className="text-gray-100 text-center p-12">
        Job details not found!
      </div>
    );
  }

  return (
    <div className="container mx-auto p-12 text-gray-100">
      <h1 className="text-3xl font-bold">{jobDetails.title}</h1>
      <p className="text-lg text-gray-400 mt-2">{jobDetails.description}</p>
      <div className="mt-6">
        <p>
          <strong>Location:</strong> {jobDetails.location}
        </p>
        <p>
          <strong>Type:</strong> {jobDetails.jobType} | {jobDetails.workNature}
        </p>
        <p>
          <strong>Client:</strong> {jobDetails.clientName}
        </p>
        <p>
          <strong>Pay:</strong> ${jobDetails.payPerHour} USD/hour
        </p>
        <p>
          <strong>HR Email:</strong>{" "}
          <a
            href={`mailto:${jobDetails.email}`}
            className="text-blue-500 hover:underline"
          >
            {jobDetails.email}
          </a>
        </p>
      </div>
    </div>
  );
}

export default JobDetails;
